<template>
    <div class="container">
        <!-- <banner></banner> -->
        <div style="padding-top: 10px">
            <bread-page>
                <menu-page>
                    <div class="menu-content" slot="menuList">
                        <div class="menu-left-con">
                            <img src="../../assets/gongxu_icon_xuqiuxinxi@3x.png" alt="" />
                            <div class="menu-left-con_title">知产转化数据</div>
                        </div>
                        <el-menu :default-active="active" class="menu-content" unique-opened router>
                            <sys-menu v-for="item in menus" :menu="item" :key="item.id" isSecond="true"></sys-menu>
                        </el-menu>
                    </div>

                    <div class="main">
                        <router-view ref="child"></router-view>
                    </div>
                </menu-page>
            </bread-page>
        </div>
        <!-- </bread-page> -->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Banner from '../../components/Banner.vue';
import MenuPage from '../../components/page/MenuPage.vue';
import BreadPage from '../../components/page/BreadPage.vue';
import SysMenu from '../../components/SysMenu.vue';
import menu from '../../mixins/menu';
export default {
    components: { MenuPage, Banner, SysMenu, BreadPage },
    mixins: [menu],
    data() {
        return {};
    },
    computed: {
        ...mapState(['showAdd'])
    },
    // watch: {
    //     $route() {
    //         this.scrollTo();
    //     }
    // },
    mounted() {
        this.$http
            .post('navigation/backAll', {
                type: 'MARKET'
            })
            .then(res => {
                this.menus = res;
                this.findActive();

                // this.scrollTo(true);
            });
        if (this.showAdd) {
            console.log('新增');
            this.$store.commit('updateShowAdd', false);
            this.$nextTick(() => {
                if (this.$refs.child.addInfo) {
                    this.$refs.child.addInfo();
                }
                if (this.$refs.child.btnEvent) {
                    this.$refs.child.btnEvent();
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.container {
    background-color: @bg;
}
.menu-left-con {
    height: 60px;
    background: #01a041;
    display: flex;
    padding-left: 34px;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    .menu-left-con_title {
        font-size: 16px;
    }
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}
// /deep/.el-breadcrumb{
//     padding-bottom: 0px !important;
//     padding-left: 50px !important;
// }
.main {
    min-height: 1080px;
    flex-grow: 1;
    .flex-col();
}
/deep/ .el-menu-item {
    padding-left: 68px !important;
}
/deep/ .el-submenu__title {
    margin-top: 10px;
}
.menu-content {
    min-height: 800px;
    background-color: #fff;
}
/deep/ .el-menu > .el-menu-item {
    &.is-active {
        background: linear-gradient(90deg, rgba(1, 160, 65, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);
        color: #01a041;
    }
    // &.is-active::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     height: 60px;
    //     width: 1px;
    //     background-color: #01a041;
    // }
}
/deep/ .el-menu > .el-submenu {
    &.is-active {
        position: relative;
    }
    &.is-active::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 60px;
        width: 1px;
        background-color: #01a041;
    }
}
</style>
